/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.contact-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  /* object-fit: fill; */
  /* object-fit: contain; */
  object-fit: cover;
  /* object-fit: scale-down; */
}
.contact-flag {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  /* object-fit: fill; */
  /* object-fit: contain; */
  object-fit: cover;
  /* object-fit: scale-down; */
}
.home-team-img{
  height: 70px;
  opacity: 25%;
  /* width: 25px; */
  /* border-radius: 50%; */
  /* object-fit: fill; */
  /* object-fit: contain; */
  /* object-fit: cover; */
  /* object-fit: scale-down; */
}
.home-img2{
  height: 70px;
  /* width: 25px; */
  /* border-radius: 50%; */
  /* object-fit: fill; */
  /* object-fit: contain; */
  /* object-fit: cover; */
  /* object-fit: scale-down; */
}
.home-img1{
  height: 70px;
 
}

.about-img{
  height: 55px;
  padding: 12px;
  /* width: 25px; */
  /* border-radius: 50%; */
  /* object-fit: fill; */
  /* object-fit: contain; */
  /* object-fit: cover; */
  /* object-fit: scale-down; */
}
.cert-img{
  /* max-height: 100%; */
  max-height: 88vh;
  width: auto;
  /* padding: 12px; */
  z-index: 999;
  border-radius: 20px;
  border: 5px solid hsl(120, 100%, 50%, .83);
 
 /* color: hsl(291, 64%, 42%) */
  /* width: 25px; */
  /* border-radius: 50%; */
  /* object-fit: fill; */
  /* object-fit: contain; */
  /* object-fit: cover; */
  /* object-fit: scale-down; */
}
.chainz-body{
  z-index: 999;
}
.chainz-img{
 
  max-height: 90vh;
  width: auto;
  padding: 12px;
  z-index: 999;
 
}

@keyframes showContent {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes hideContent {
  from {opacity: 1;}
  to {opacity: 0;}
}

/* SCROLLLLLE */

/* ::-webkit-scrollbar the scrollbar.
::-webkit-scrollbar-button the buttons on the scrollbar (arrows pointing upwards and downwards).
::-webkit-scrollbar-thumb the draggable scrolling handle.
::-webkit-scrollbar-track the track (progress bar) of the scrollbar.
::-webkit-scrollbar-track-piece the track (progress bar) NOT covered by the handle.
::-webkit-scrollbar-corner the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet.
::-webkit-resizer the draggable resizing handle that appears at the bottom corner of some elements. */

/* Hide scrollbar for Chrome, Safari and Opera */


/* Hide scrollbar for IE and Edge */
 /* #scroll-content {
  overflow-y: none;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}   */



/* #scroll-content::-webkit-scrollbar {
  width: 5px; 
}


#scroll-content::-webkit-scrollbar-track {

  box-shadow: rgb(238 23 35); 

}
 

#scroll-content::-webkit-scrollbar-thumb {
  background: black; 

  
}


#scroll-content::-webkit-scrollbar-thumb:hover {
  background: black; 
} */


/* ::-webkit-scrollbar {
  display: none;
  width: 10px;
  
} */








/* ====================== */
#sticky-content {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}